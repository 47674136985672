var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "40%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
              ref: "refForm",
              attrs: {
                "label-width": "200px",
                model: _vm.formData,
                rules: _vm.formRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "充值金额：", prop: "recharge_amount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.recharge_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "recharge_amount", $$v)
                      },
                      expression: "formData.recharge_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "赠送金额：", prop: "gift_amount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.gift_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "gift_amount", $$v)
                      },
                      expression: "formData.gift_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否在小程序展示：", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "status", $$v)
                        },
                        expression: "formData.status",
                      },
                    },
                    _vm._l(_vm.configList, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.onHandleSubmit },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }