var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "page-wrap" },
    [
      _c("section", { staticClass: "form" }, [
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-button", {
              staticClass: "refresh",
              staticStyle: { "margin-left": "5px" },
              attrs: { type: "primary", icon: "el-icon-refresh" },
              on: { click: _vm.onHandleRefresh },
            }),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onHandleCreate } },
              [_vm._v("新 增")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "section",
        { staticClass: "content" },
        [
          _c("table-list", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingData,
                expression: "loadingData",
              },
            ],
            attrs: { tableData: _vm.tableData },
            on: { "on-show-dialog": _vm.onHandleShowDialog },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.formData.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.formData.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.onHandleSizeChange,
              "current-change": _vm.onHandleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("edit-member-dialog", {
        ref: "refEditMemberDialog",
        on: { "on-edit-config": _vm.onHandleEditConfig },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }