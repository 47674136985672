<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="40%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form
        label-width="200px"
        ref="refForm"
        v-loading="dialogLoading"
        :model="formData"
        :rules="formRules"
      >
        <el-form-item label="充值金额：" prop="recharge_amount">
          <el-input v-model="formData.recharge_amount" placeholder="请输入">
          </el-input>
        </el-form-item>

        <el-form-item label="赠送金额：" prop="gift_amount">
          <el-input v-model="formData.gift_amount" placeholder="请输入">
          </el-input>
        </el-form-item>

        <el-form-item label="是否在小程序展示：" prop="status">
          <el-radio-group v-model="formData.status">
            <el-radio
              v-for="item of configList"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { IS_MIN_APP_SHOW } from "../../utils/enum/index";
import { postMemberTopUpEdit } from "@/api/member/index";
import { validInteger } from "@/utils/tools/validate";

export default {
  name: "EditMemberDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      title: "新增会员充值",
      IS_MIN_APP_SHOW,
      configList: Object.values(IS_MIN_APP_SHOW),
      formData: {
        status: IS_MIN_APP_SHOW.no.value, // 是否开启
        recharge_amount: "", // 充值金额
        gift_amount: "", // 赠送金额
      },
      formDataClone: {}, // 拷贝一份数据，用于初始化
      submitLoading: false, // 提交按钮禁用
      formRules: {
        recharge_amount: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              console.log("🆒 recharge_amount", value);
              if (!value) {
                callback(new Error("请输入充值金额！"));
              }
              if (validInteger(value)) {
                callback(new Error("金额为大于等于0的整数！"));
              }
              callback();
            },
          },
        ],
        gift_amount: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入赠送金额！"));
              }
              if (validInteger(value)) {
                callback(new Error("金额为大于等于0的整数！"));
              }
              callback();
            },
          },
        ],
      }, // 表单校验
      dataId: null, // 编辑数据的id
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      if (data?.id) {
        this.dataId = data.id;
        this.title = "编辑会员充值";
        this.onInitEditForm(data);
      }
      this.$refs.refForm?.clearValidate();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.$refs.refForm?.clearValidate();
      this.formData = {
        ...this.formDataClone,
      };
      this.dialogVisible = false;
      this.dataId = null;
      this.title = "新增会员充值";
    },
    /**
     * 初始化编辑页面
     */
    onInitEditForm(data) {
      this.dialogLoading = true;
      this.formData = {
        ...data.formData,
      };
      // console.log("🆒 onInitEditForm", data);
      this.dialogLoading = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.submitLoading = true;
        const params = {
          ...this.formData,
        };
        this.dataId && Object.assign(params, { id: this.dataId });
        this.ajaxPostMemberTopUpEdit(params);
      });
    },
    /**
     * 会员充值，保存
     */
    async ajaxPostMemberTopUpEdit(params) {
      try {
        await postMemberTopUpEdit(params);
        this.$message.success("保存成功");
        this.onHandleClose();
        this.$emit("on-edit-config");
      } catch (err) {
        console.log("ajax postMemberTopUpEdit err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding: 30px 20px 20px;
    box-sizing: border-box;
    .el-form {
      &-item {
        .el-select,
        .el-input {
          width: 350px;
        }
      }
    }
  }
}
</style>
