<template>
  <!-- table start -->
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column type="index" align="center" width="100" label="序号">
    </el-table-column>
    <el-table-column
      prop="recharge_amount"
      align="center"
      label="充值金额"
      min-width="150"
    ></el-table-column>
    <el-table-column
      prop="gift_amount"
      align="center"
      label="赠送金额"
      min-width="150"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="status"
      align="center"
      label="是否显示在小程序首页"
      min-width="200"
    >
      <template slot-scope="scope">
        <span>{{
          Object.values(IS_MIN_APP_SHOW).find(
            (item) =>
              item.value === (scope.row.status || IS_MIN_APP_SHOW.no.value)
          ).label
        }}</span>
      </template>
    </el-table-column>

    <el-table-column label="操作" min-width="150" align="center" fixed="right">
      <template slot-scope="scope">
        <el-button type="text" @click="onHandleEditData(scope.row)"
          >编辑</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <!-- table end -->
</template>

<script>
import { IS_MIN_APP_SHOW } from "../../utils/enum/index";

export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      IS_MIN_APP_SHOW,
    };
  },
  computed: {
    /**
     * 售后品类前三
     */
    getFlattenedProducts() {
      return (data) => {
        let res = "";
        data.forEach((item) => {
          res += item.product_title + "、";
        });
        return res || "-";
      };
    },
  },
  methods: {
    /**
     * 编辑
     */
    onHandleEditData(row) {
      this.$emit("on-show-dialog", row);
    },
    /**
     * 详情
     */
    onHandleDetail(row) {
      const query = {
        id: row.id,
      };
      this.$router.push({
        path: "/data-detail",
        query,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
