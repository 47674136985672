<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form">
      <div class="form-item">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
        <el-button @click="onHandleCreate" type="primary">新 增</el-button>
      </div>
    </section>
    <!-- 顶部操作 end -->

    <!-- 列表 start -->
    <section class="content">
      <table-list
        :tableData="tableData"
        v-loading="loadingData"
        @on-show-dialog="onHandleShowDialog"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="pagination">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 会员充值档位列表，新增、编辑 start -->
    <edit-member-dialog
      ref="refEditMemberDialog"
      @on-edit-config="onHandleEditConfig"
    ></edit-member-dialog>
    <!-- 会员充值档位列表，新增、编辑 end -->
  </section>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import TableList from "./modules/table-list/index.vue";
import EditMemberDialog from "./modules/edit-member-dialog/index.vue";
import { getMemberTopUpList } from "@/api/member/index";
// import { mockData } from "./mock";

export default {
  name: "MemberLevelConfig",
  components: {
    TableList,
    EditMemberDialog,
  },
  data() {
    return {
      loadingData: false,
      formData: {
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 复制一份，用于初始化
      tableData: [],
      total: 0,
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.ajaxGetTableData();
    },
    /**
     * 查询列表数据
     */
    async ajaxGetTableData() {
      this.loadingData = true;
      const params = { ...this.formData };
      try {
        const { data } = await getMemberTopUpList(params);
        const { total, data: list } = data;
        this.total = total;
        this.tableData = list;
      } catch (err) {
        console.log("ajax getMemberTopUpList err", err);
      } finally {
        this.loadingData = false;
        // 模拟数据
        // this.total = mockData.total;
        // this.tableData = mockData.tableData;
      }
    },
    /**
     * 新增数据
     */
    onHandleCreate() {
      this.$refs.refEditMemberDialog.onInitData();
    },
    /**
     * 编辑数据
     */
    onHandleShowDialog(row) {
      const { id, status, recharge_amount, gift_amount } = row;
      const params = {
        id,
        formData: {
          status,
          recharge_amount,
          gift_amount,
        },
      };
      this.$refs.refEditMemberDialog.onInitData(params);
    },
    /**
     * 新增、编辑了数据
     */
    onHandleEditConfig() {
      this.ajaxGetTableData();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.ajaxGetTableData();
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.ajaxGetTableData();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 13px 15px;
    box-sizing: border-box;
    background: #ffffff;
    &-item {
      flex: 1;
      &:nth-of-type(2) {
        text-align: right;
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }

  .content {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
