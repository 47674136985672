/** 是否开启设置 */
export const IS_MIN_APP_SHOW = {
  yes: {
    value: 1,
    label: '是'
  },
  no: {
    value: 0,
    label: '否'
  },
}
