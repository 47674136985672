var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: { type: "index", align: "center", width: "100", label: "序号" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "recharge_amount",
          align: "center",
          label: "充值金额",
          "min-width": "150",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "gift_amount",
          align: "center",
          label: "赠送金额",
          "min-width": "150",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "status",
          align: "center",
          label: "是否显示在小程序首页",
          "min-width": "200",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      Object.values(_vm.IS_MIN_APP_SHOW).find(
                        (item) =>
                          item.value ===
                          (scope.row.status || _vm.IS_MIN_APP_SHOW.no.value)
                      ).label
                    )
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          label: "操作",
          "min-width": "150",
          align: "center",
          fixed: "right",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleEditData(scope.row)
                      },
                    },
                  },
                  [_vm._v("编辑")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }